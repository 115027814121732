<template>
  <div
    ref="menuRef"
    class="w-full rounded-2xl bg-[#191919]"
    :class="!menuCollapsedOnMobile ? 'max-md:bg-transparent' : ''"
  >
    <MobileMenuBtn :title="selectedTitle || ''" @click="toggleMenu" />
    <div
      class="absolute left-0 top-0 z-50 w-full flex-col rounded-3xl bg-[#141414] transition-all duration-200 max-md:mx-4 max-md:my-3 max-md:w-[calc(100%_-_32px)] max-md:py-2.5 md:static md:flex-row md:bg-transparent md:!shadow-none md:[&>div:first-child>a]:rounded-l-2xl md:[&>div:last-child>a]:rounded-r-2xl"
      style="
        box-shadow:
          0px 966px 270px 0px rgba(102, 75, 0, 0),
          0px 618px 247px 0px rgba(102, 75, 0, 0.02),
          0px 348px 209px 0px rgba(102, 75, 0, 0.08),
          0px 155px 155px 0px rgba(102, 75, 0, 0.14),
          0px 39px 85px 0px rgba(102, 75, 0, 0.16),
          0px 4px 50px 0px rgba(0, 0, 0, 0.25);
      "
      :class="menuCollapsedOnMobile ? 'hidden md:flex' : 'flex'"
    >
      <div class="flex items-center gap-3.5 px-5 py-2 md:hidden">
        <img class="h-10" src="/images/big_logo.png" alt="" />
        <img class="h-5" src="/images/eldorado_logo_title.png" alt="" />
        <div
          class="rounded-full border border-solid border-[#33FF99]/10 bg-[#33FF99]/10 px-2 py-1 text-[13px] leading-none text-[#33FF99]"
        >
          Beta
        </div>
        <button
          class="ml-auto flex cursor-pointer items-center justify-center rounded-lg py-2.5 pl-5 text-xs text-white/50"
          @click="toggleMenu"
        >
          <Icon icon="icon-park-outline:up" />
        </button>
      </div>
      <div class="mx-5 my-2.5 flex h-[1px] w-[calc(100%_-_40px)] bg-white/5 md:hidden" />
      <template v-for="(item, i) in menuLinks" :key="i">
        <MenuLink
          v-if="!item.subLinks"
          :title="item.name"
          :link-to="item.link"
          :out-link="item.out"
          main-btn-class="max-md:!text-lg max-md:text-white max-md:!px-[20px]"
          @click="toggleMenu"
        />
        <MenuLink
          v-else
          :title="item.name"
          :link-to="item.link"
          :out-link="item.out"
          :sub-links="item.subLinks"
          main-btn-class="max-md:!text-lg max-md:text-white max-md:!px-[20px]"
          sub-panel-class="max-md:bg-[#C99D291A] max-md:!pt-0 max-md:[&>div]:bg-transparent max-md:[&>div]:!shadow-none md:[&>div]:py-2.5"
          :expanded-on-mobile="i === expandedMenuNo"
          is-passive-menu
          @toggle-expand="setExpandedMenuNo(i)"
          @toggle-collapse="i === expandedMenuNo && setExpandedMenuNo(NaN)"
        >
          <template v-for="(subItem, j) in item.subLinks" :key="j">
            <MenuLink
              is-sub-link
              :title="subItem.name"
              :link-to="subItem.link"
              :out-link="subItem.out"
              main-btn-class="max-md:!text-lg"
              @click="toggleMenu"
            />
          </template>
        </MenuLink>
      </template>
      <div class="grid gap-2.5 px-5 py-2.5 md:hidden">
        <button
          v-if="!isWalletConnected"
          class="larken flex w-full items-center justify-center gap-1.5 rounded-2xl bg-white/5 px-5 py-4 text-xl"
          @click="toggleMenu(), !isWalletConnected && showConnectWalletModal()"
        >
          <div class="relative">
            <img class="aspect-square w-5" src="/icons/wallet.svg" alt="" />
            <Icon
              class="absolute -right-[0.1em] -top-[0.1em] text-[0.5em] text-[#33FF99]"
              icon="mingcute:plus-fill"
            />
          </div>
          Connect Wallet
        </button>
        <MemberBtn
          v-if="runtimeConfig.public.ENABLE_DORITO === 'true' && !isCCMember"
          class="w-full"
          @click="showDoritoJoinModal"
        />
      </div>
      <div class="grid grid-cols-3 justify-items-center gap-2.5 text-xl md:hidden">
        <HeaderSocialLink
          link="https://docs.eldorado.market/el-dorado"
          icon="lucide:book-open-text"
          @click="toggleMenu"
        />
        <HeaderSocialLink
          link="https://x.com/eldorado_market"
          icon="ri:twitter-x-line"
          @click="toggleMenu"
        />
        <HeaderSocialLink
          link="https://t.me/+urSStv3-sc8yMjI8"
          icon="mingcute:telegram-fill"
          @click="toggleMenu"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRuntimeConfig } from 'nuxt/app'
import { Icon } from '@iconify/vue'
import { storeToRefs } from 'pinia'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import MenuLink from '../MenuLink.vue'
import HeaderSocialLink from './HeaderSocialLink.vue'
import MobileMenuBtn from './MobileMenuBtn.vue'
import MemberBtn from './MemberBtn.vue'
import { useSwapkitWalletStore } from '~/store/swapkitWalletStore'
import { useConnectWalletModalStore } from '~/store/connectWalletModalStore'
import { useDoritoJoinModalStore } from '~/store/doritoJoinModalStore'

const props = defineProps<{
  menuCollapsedOnMobile?: boolean
}>()
const emit = defineEmits<{
  toggleMenu: [boolean]
  routeChange: [string | undefined]
}>()

const runtimeConfig = useRuntimeConfig()
const { showDoritoJoinModal } = useDoritoJoinModalStore()

const menuRef = ref<HTMLElement | null>(null)

const { isCCMember } = storeToRefs(useSwapkitWalletStore())

const swapkitWalletStore = useSwapkitWalletStore()
const { isWalletConnected } = storeToRefs(swapkitWalletStore)
const { showConnectWalletModal } = useConnectWalletModalStore()

const toggleMenu = () => {
  emit('toggleMenu', !props.menuCollapsedOnMobile)
}
const handleClickOutside = (event: MouseEvent) => {
  if (
    !props.menuCollapsedOnMobile &&
    menuRef.value &&
    !(menuRef.value as HTMLElement).contains(event.target as Node)
  ) {
    emit('toggleMenu', true)
  }
}
onMounted(() => {
  window.addEventListener('click', handleClickOutside)
})
onUnmounted(() => {
  window.removeEventListener('click', handleClickOutside)
})

interface HeaderLink {
  name: string
  link?: string
  out?: boolean
  subLinks?: HeaderLink[]
}
const menuLinks: HeaderLink[] = [
  { name: 'Swap', link: '/swap' },
  {
    name: 'Earn',
    link: '/earn',
  },
  {
    name: 'Markets',
    link: '/markets',
  },
  // {
  //   name: 'Learn',
  //   subLinks: [
  //     {
  //       name: "Beginner's Guide",
  //       link: 'https://el-dorado.gitbook.io/el-dorado/introduction-to-el-dorado/beginners-guide-zero-to-one',
  //       out: true,
  //     },
  //     {
  //       name: 'El Dorado Gitbook',
  //       link: 'https://el-dorado.gitbook.io/el-dorado/introduction-to-el-dorado/vision',
  //       out: true,
  //     },
  //     { name: 'Maya Protocol', link: 'https://docs.mayaprotocol.com/', out: true },
  //     { name: 'Chainflip Protocol', link: 'https://chainflip.io/vision', out: true },
  //   ],
  // },
  {
    name: 'Ramp',
    subLinks: [
      { name: 'Kado', link: '/buy' },
      { name: 'P2P', link: '/p2p' },
    ],
  },
]
const route = useRoute()

const selectedTitle = computed(() => {
  let title
  for (const { link, subLinks, name } of menuLinks) {
    if (link) {
      if (route.path === link || route.path === `/${link}`) {
        title = name
      }
    } else if (subLinks && subLinks.length > 0) {
      for (const { link, name } of subLinks) {
        if (route.path === link || route.path === `/${link}`) {
          title = name
        }
      }
    }
  }
  emit('routeChange', title)
  return title
})

const expandedMenuNo = ref(NaN)
const setExpandedMenuNo = (no: number) => {
  expandedMenuNo.value = no
}
</script>
